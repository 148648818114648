import React from 'react';

import firebase from './Firebase';

import Preloader from "./Components/Preloader";
import Header from "./Components/Header";
import Banner from "./Components/Banner";
import HomeBoxes from "./Components/HomeBoxes";
import HomeFacts from "./Components/HomeFacts";
import Download from "./Components/Download";
import DomainFacts from "./Components/DomainFacts";
import Location from "./Components/Location";
import MetaTags from 'react-meta-tags';

import './App.css';
import Footer from "./Components/Footer";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            domains: null,
            domain: null,
            slug : null
        };
    }

    componentDidMount() {
        let path = window.location.pathname;
        let slugs = path.split('/');

        if (path === '/') {
            firebase.firestore()
                .collection('domains')
                .get()
                .then((snapshot) => {
                    const domains = snapshot.docs.map(doc => Object.assign(doc.data(), {id: doc.id}));
                    console.log(domains);

                    this.setState({
                        domains: domains
                    });
                });
        } else if (slugs[1] === 'domain' && slugs[2] !== undefined) {
            firebase.firestore()
                .collection('domains')
                .where('urlSlug', '==', slugs[2])
                .get()
                .then((snapshot) => {
                    const domain = snapshot.docs.map(doc => Object.assign(doc.data(), {id: doc.id})).pop();

                    this.setState({
                        domain: domain
                    });
                });
        }
    }

    render() {
        if (this.state.domains === null && this.state.domain === null) {
            return (
                <Preloader/>
            );
        }

        if (this.state.domains !== null) {
            return (
                <div>
                    <MetaTags>
                        <title>Slopes Info</title>
                        <meta name={"description"} content={"Poți vede starea pârtiilor din România, statusul" +
                        "instalațiilor pe cablu și informații despre vreme. Deasemenea vei fi notificat când o" +
                        "pârtie favorită a fost deschisă"} />
                    </MetaTags>
                    <Header/>
                    <Banner/>
                    <HomeBoxes/>
                    <HomeFacts domains={this.state.domains}/>
                    <Location domains={this.state.domains}/>
                    <Download/>
                    <Footer/>
                </div>
            );
        }

        if (this.state.domain !== null) {
            let shortDescription = "Domeniul schiabil " + this.state.domain.name + " pune la dispozitie " +
                this.state.domain.slopesCount + " pârtii, cu o lungime totală de " +
                Math.round(this.state.domain.slopesLength / 1000) + " km și " + this.state.domain.lifts.length +
                " instalații pe cablu.";

            return (
                <div>
                    <MetaTags>
                        <title>Partii de schi la {this.state.domain.name} - Slopes info</title>
                        <meta
                            name={"description"}
                            content={shortDescription}
                        />
                        <link rel="canonical" href={'https://slopes.info/domain/' + this.state.domain.urlSlug} />
                    </MetaTags>
                    <Header/>
                    <DomainFacts description={shortDescription} domain={this.state.domain}/>
                    <Location domain={this.state.domain}/>
                    <Download/>
                    <Footer/>
                </div>
            );
        }
    }
}

export default App;

import React from 'react';

class InfoCard extends React.Component {
    render() {
        return (
            <div className="col-lg-4 col-md-4 col-12" style={{
                pointerEvents: 'initial'
            }}>
                <div className="price-table text-center aos-init" data-aos="fade-right" data-aos-delay="400" style={{
                    backgroundColor: 'white'
                }}>
                    <div className="top background-fullwidth" style={{
                        backgroundImage: 'url(/src/gray-bg.jpg)'
                    }}>
                        <h4>{this.props.domain.name}</h4>
                    </div>
                    <div className="bottom">
                        <ul>
                            <li>{this.props.domain.slopesCount} pârtii</li>
                            <li>{this.props.domain.lifts.length} instalații cablu</li>
                            <li>{
                                Math.round(
                                    this.props.domain.slopesLength / 1000,
                                    2
                                )
                            }km pârtie</li>
                            <li>{this.props.domain.snowLevel}cm zăpadă</li>
                        </ul>
                        <div className="space-25"/>
                        <a href={'/domain/' + this.props.domain.urlSlug}
                           className="btn btn-primary btn-white btn-theme"><span>Detalii</span></a>
                    </div>
                </div>
            </div>
        );
    }
}

export default InfoCard;

import React from 'react';

function Preloader() {
  return (
      <div className="preloader">
          <div className="loader-wrapper">
              <div className="loader"/>
          </div>
      </div>
  );
}

export default Preloader;

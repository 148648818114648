import firebase from 'firebase/app';
import 'firebase/firestore';

// Your web app's Firebase configuration
let firebaseConfig = {
    apiKey: "AIzaSyA7e0mmZGqzHiNP4E4Ui8S3bh86wcA0aTc",
    authDomain: "slopes-info-f3f59.firebaseapp.com",
    databaseURL: "https://slopes-info-f3f59.firebaseio.com",
    projectId: "slopes-info-f3f59",
    storageBucket: "slopes-info-f3f59.appspot.com",
    messagingSenderId: "623071693948",
    appId: "1:623071693948:web:eb30882feb68366c740df9",
    measurementId: "G-EWR1HVFJ3P"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;

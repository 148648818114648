import React from 'react';

import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';

import InfoCard from "./InfoCard";

class Location extends React.Component {
    state = {
        domain: null,
        showingInfoWindow: false,
        activeMarker: null
    };

    onMarkerClick = (props, marker, e) => {
        if (this.props.domains === undefined) return;

        let domain = this.props.domains.filter(domain => domain.id === marker.id).pop();

        this.setState({
            domain: domain,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };

    render() {
        let markers = [];
        let center = {
            lat: 45.9442858,
            long: 25.0094303
        };
        let zoom = 6;

        if (this.props.domain !== undefined) {
            zoom = 15;
            center = this.props.domain.gps;
            markers.push(
                <Marker
                    onClick={this.onMarkerClick}
                    position={{
                        lat: this.props.domain.gps.lat,
                        lng: this.props.domain.gps.long
                    }}
                    name={this.props.domain.name}
                />
            );
        } else {
            this.props.domains.forEach((domain) => {
                markers.push(
                    <Marker
                        onClick={this.onMarkerClick}
                        key={domain.id}
                        id={domain.id}
                        position={{
                            lat: domain.gps.lat,
                            lng: domain.gps.long
                        }}
                        name={domain.name}
                    />
                );
            });
        }

        return (
            <div style={{
                position: "relative"
            }}>
                <section className="map">
                    <Map
                        onClick={this.onMapClicked}
                        google={this.props.google}
                        initialCenter={{
                            lat: center.lat,
                            lng: center.long
                        }}
                        zoom={zoom}
                    >
                        {markers}
                    </Map>
                </section>
                {this.state.showingInfoWindow && this.state.domain !== null &&
                <section className="prices" style={{
                    position: "absolute",
                    top: 0,
                    width: '100%',
                    pointerEvents: 'none'
                }}>
                    <div className="row flex-row-reverse" style={{
                        padding: '20px 70px'
                    }}>
                        <InfoCard domain={this.state.domain} />
                    </div>
                </section>
                }
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyA8wUO_B9x5iiUHNFylHq2bvQweM1nM-8A')
})(Location)

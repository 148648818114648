import React from 'react';

function Banner() {
    return (
        <section id="slide" className="slide background-withcolor">
            <div className="content-bottom">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 aos-init aos-animate" data-aos="fade-right">
                            <h2>Slopes Info</h2>
                            <p>
                                Aplicație destinată iubitorilor de zăpadă
                            </p>
                        </div>
                        <div className="col-md-6 aos-init aos-animate" data-aos="fade-left" data-aos-delay="200">
                            <img src="/src/mobile-3.png" className="img-fluid d-block mx-auto" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;

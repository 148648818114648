import React from 'react';

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            domain: props.domain
        };
    }

    render() {
        return (
            <footer className="pb-0">
                <div className="copyright">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-4">
                                <p>All rights reserved © Slopes Info, 2020
                                </p>
                            </div>
                            <div className="offset-md-4 col-md-4">
                                <ul className="nav justify-content-end">
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#">Terms and Conditions</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#">Privacy Policy</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;

import React from 'react';

function Header() {
    return (
        <header className="webapp-header">
            <nav className="navbar navbar-expand-lg navbar-light" id="webapp_menu">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src="/src/logo.png" className="img-fluid logo" alt="" />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_menu"
                            aria-controls="main_menu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="main_menu">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link anchor active" href="/#">Home
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link anchor" href="/#facts">Despre</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link anchor" href="/#download">Download</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;

import React from 'react';

class HomeFacts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            domains: props.domains
        };
    }

    render() {
        return (
            <section id="facts" className="fun-facts padding-100 background-withcolor">
                <div className="container">
                    <div className="row">
                        <div className="text-center col-12 section-title" data-aos="fade-zoom-in">
                            <h3>
                                <span className="white">Despre noi</span>
                            </h3>
                            <div className="space-25"/>
                            <p>
                                Păstrăm informații despre toate domeniile schiabile din România și te anunțăm despre
                                starea
                                lor în momentul în care avem informații noi.
                            </p>
                            <div className="space-50"/>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="fact-box text-center" data-aos="fade-up" data-aos-delay="400">
                                <span className="fas fa-mountain fa-3x"/>
                                <br/><br/>
                                <h5>{this.state.domains.length}</h5>
                                <h6>Domenii</h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="fact-box text-center" data-aos="fade-up" data-aos-delay="800">
                                <span className="fas fa-snowboarding fa-3x"/>
                                <br/><br/>
                                <h5>{this.state.domains.map(domain => domain.slopesCount).reduce((a, b) => a + b, 0)}</h5>
                                <h6>Pârtii</h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="fact-box text-center" data-aos="fade-up" data-aos-delay="1200">
                                <span className="fas fa-tram fa-3x"/>
                                <br/><br/>
                                <h5>{this.state.domains.map(domain => domain.lifts ? domain.lifts.length : 0).reduce((a, b) => a + b, 0)}</h5>
                                <h6>Instalații cablu</h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="fact-box text-center" data-aos="fade-up" data-aos-delay="1600">
                                <span className="fas fa-ruler fa-3x"/>
                                <br/><br/>
                                <h5>{
                                    Math.round(
                                        this.state.domains.map(domain => parseInt(domain.slopesLength) > 0 ? domain.slopesLength : 0).reduce((a, b) => a + b, 0) / 1000,
                                        2
                                    )
                                }</h5>
                                <h6>km pârtie</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HomeFacts;

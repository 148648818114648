import React from 'react';

function HomeBoxes() {
    return (
        <section id="boxes" className="boxes padding-100">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-12">
                        <div className="box aos-init" data-aos="fade-up">
                            <div className="icon">
                                <span className="fas fa-snowboarding fa-3x fa-fw"/>
                            </div>
                            <div className="space-20"/>
                            <h4>Starea pârtiilor</h4>
                            <div className="space-15"/>
                            <p>Păstrăm informații actualizate despre starea tutror pârtiilor din România</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="box aos-init" data-aos="fade-up" data-aos-delay="200">
                            <div className="icon">
                                <span className="fas fa-tram fa-3x fa-fw"/>
                            </div>
                            <div className="space-20"/>
                            <h4>Starea instalațiilor</h4>
                            <div className="space-15"/>
                            <p>Cu ajutorul utilizatorilor, starea instalațiilor pe cablu este disponibilă</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="box aos-init" data-aos="fade-up" data-aos-delay="400">
                            <div className="icon">
                                <span className="fas fa-cloud fa-3x fa-fw"/>
                            </div>
                            <div className="space-20"/>
                            <h4>Meteo</h4>
                            <div className="space-15"/>
                            <p>Informații despre temperatură și precipitații este oferită pentru toate domeniile
                                schiabile</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeBoxes;

import React from 'react';

function Download() {
    return (
        <section id="download" className="download-app padding-100 pb-0 background-fullwidth background-fixed">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-12 aos-init" data-aos="fade-right">
                        <h2>Descarcă aplicația gratuit</h2>
                        <p>
                            Poți descărca aplicația pentru telefonul tău Apple sau Android pentru a începe să primești
                            notificări
                        </p>
                        <a href="/links/app-install" className="btn btn-primary shadow btn-colord btn-theme"
                           tabIndex="0">
                            <i className="fab fa-apple"/>
                            <span>Descarcă din<br/>APP STORE</span>
                        </a>
                        <a href="/links/app-install" className="btn btn-primary shadow  btn-colord btn-theme"
                           tabIndex="0">
                            <i className="fab fa-google-play"/>
                            <span>Descarcă din<br/>GOOGLE PLAY</span>
                        </a>
                    </div>
                    <div className="col-lg-6 col-12 aos-init" data-aos="fade-left" data-aos-delay="400">
                        <img src="/src/mobile-6.png" className="img-fluid d-block mx-auto" alt=""/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Download;

import React from 'react';

class DomainFacts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            domain: props.domain
        };
    }

    render() {
        return (
            <section id="facts" className="fun-facts padding-100 background-withcolor">
                <div className="container padding-100">
                    <div className="row">
                        <div className="text-center col-12 section-title" data-aos="fade-zoom-in">
                            <h3>
                                <span className="white">{this.state.domain.name}</span>
                            </h3>
                            <div className="space-25"/>
                            <p>
                                {this.props.description/* + " Pârtiile sunt situate între 1400m și 2000m altitudine."*/}
                            </p>
                            <p>
                                {}
                            </p>
                            <div className="space-50"/>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="fact-box text-center" data-aos="fade-up" data-aos-delay="400">
                                <span className="fas fa-snowboarding fa-3x"/>
                                <br/><br/>
                                <h5>{this.state.domain.slopesCount}</h5>
                                <h6>Pârtii</h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="fact-box text-center" data-aos="fade-up" data-aos-delay="800">
                                <span className="fas fa-tram fa-3x"/>
                                <br/><br/>
                                <h5>{this.state.domain.lifts.length}</h5>
                                <h6>Instalații cablu</h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="fact-box text-center" data-aos="fade-up" data-aos-delay="1200">
                                <span className="fas fa-ruler fa-3x"/>
                                <br/><br/>
                                <h5>{
                                    Math.round(
                                        this.state.domain.slopesLength / 1000,
                                        2
                                    )
                                }</h5>
                                <h6>km pârtie</h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="fact-box text-center" data-aos="fade-up" data-aos-delay="1600">
                                <span className="fas fa-snowflake fa-3x"/>
                                <br/><br/>
                                <h5>{this.state.domain.snowLevel}</h5>
                                <h6>cm zăpadă</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default DomainFacts;
